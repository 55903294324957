import './App.css';
import logo2 from './app-icon.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo2} className="App-logo" alt="logo" />
        <p>
          Temple System 2022
        </p>
      </header>
    </div>
  );
}

export default App;
